<!-- =========================================================================================
    File Name: VerticalNavMenuItem.vue
    Description: Vertical NavMenu item component. Extends vuesax framework's 'vs-sidebar-item' component
    Component Name: VerticalNavMenuItem
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    v-if="canSee"
    class="vs-sidebar--item"
    :class="[
      {'vs-sidebar-item-active'            : activeLink},
      {'disabled-item pointer-events-none' : isDisabled}
    ]" >

      <router-link
        tabindex="-1"
        v-if="to"
        exact
        :class="[{'router-link-active': activeLink}]"
        :to="to"
        :target="target" >
          <vs-icon v-if="!featherIcon" :icon-pack="iconPack" :icon="icon" />
          <feather-icon v-else :class="{'w-3 h-3': iconSmall}" :icon="icon" />
          <slot />
      </router-link>

      <a v-else :target="target" :href="href" tabindex="-1">
        <vs-icon v-if="!featherIcon" :icon-pack="iconPack" :icon="icon" />
        <feather-icon v-else :class="{'w-3 h-3': iconSmall}" :icon="icon" />
        <slot />
      </a>
  </div>
</template>

<script>
export default {
  name: 'v-nav-menu-item',
  props: {
    icon        : { type: String,                 default: ""               },
    iconSmall   : { type: Boolean,                default: false            },
    iconPack    : { type: String,                 default: 'material-icons' },
    href        : { type: [String, null],         default: '#'              },
    to          : { type: [String, Object, null], default: null             },
    slug        : { type: String,                 default: null             },
    index       : { type: [String, Number],       default: null             },
    featherIcon : { type: Boolean,                default: true             },
    target      : { type: String,                 default: '_self'          },
    isDisabled  : { type: Boolean,                default: false            },
  },
  computed: {
    isConceptOuConceptLab() {
        let idEmpresa = this.$store.state.AppActiveUser.idEmpresa;
        if (idEmpresa === 1 || idEmpresa === 40) {
            return true;
        }

        return false;
    },
    canSee() {

       if (this.slug === "menu-backend-mancha-urbana") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR')) {
                  return true;   
               }
          return false;
      }

      if (this.slug === "menu-home") {
        if (this.$store.state.AppActiveUser.perfil === 'MONITOR_PANICO' || this.$store.state.AppActiveUser.perfil === 'EMPRESA_MONITOR_PANICO') {
          return false;
        } else {
            return true;
        }
      }

      if (this.slug === "menu-cadastro-marca-veiculo") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR')) {
                  return true;   
               }
          return false;
      }

      if (this.slug === "menu-cadastro-modelo-veiculo") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR')) {
                  return true;   
               }
          return false;
      }

      if (this.slug === "menu-cadastro") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'CONFIGURADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'FINANCEIRO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') || 
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-cadastro-acessorio-modulos") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||               
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR')||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-cadastro-clientes") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'FINANCEIRO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR')||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-cadastro-modulos") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'CONFIGURADOR') ||               
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR')||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR')||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-cadastro-tecnicos") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'FINANCEIRO') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR')||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR')||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-cadastro-ordem-servico") {
            if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'FINANCEIRO') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'CONFIGURADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR')||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR')||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
            }
          return false;
      }

      if (this.slug === "menu-cadastro-usuarios") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||                              
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||                                
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA')||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }

      if (this.slug === "menu-cadastro-pre-cadastro") {
            if (this.isConceptOuConceptLab) {
                if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
                    (this.$store.state.AppActiveUser.perfil === 'COMERCIAL') || 
                    (this.$store.state.AppActiveUser.perfil === 'FINANCEIRO') ||
                    (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
                    (this.$store.state.AppActiveUser.perfil === 'EMPRESA_COMERCIAL') 
                    ) {
                    return true;   
                }
            }
            return false;
      }

      if (this.slug === "menu-cadastro-ocorrencia") {
            if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||               
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'CONFIGURADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR')||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR')||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
            }
          return false;
      }

      if (this.slug === "menu-cadastro-empresas") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||                              
               (this.$store.state.AppActiveUser.perfil === 'FINANCEIRO')  ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR')  ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR')) {
            return true;   
         }
          return false;
      }

      if (this.slug === "menu-cadastro-tipo-mensagem-teclado") {
           if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||                              
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR')||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }

      if (this.slug === "menu-cadastro-simcard") {
           if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||               
               (this.$store.state.AppActiveUser.perfil === 'FINANCEIRO') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'CONFIGURADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR')||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      
      if (this.slug === "menu-backend") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'MONITOR_PANICO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_MONITOR_PANICO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-backend-monitoramento-veiculos") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'MONITOR_PANICO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_MONITOR_PANICO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-backend-localizacao-veiculos") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'CONFIGURADOR') ||               
               (this.$store.state.AppActiveUser.perfil === 'MONITOR_PANICO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_MONITOR_PANICO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')
               ) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-backend-alteracao-veiculo" || this.slug === "menu-backend-alteracao-veiculo-em-lote") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'FINANCEIRO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-backend-tabela-precos-cliente") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'FINANCEIRO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-backend-tabela-precos-central") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'FINANCEIRO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-backend-impressao-contrato") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'FINANCEIRO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-backend-impressao-formulario-pedido") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'FINANCEIRO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-backend-impressao-formulario-pedido-manual") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'FINANCEIRO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'COMERCIAL') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_COMERCIAL') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-backend-impressao-formulario-pedido-manual-pre-cadastro") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'FINANCEIRO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||               
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'COMERCIAL') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_COMERCIAL') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }

      if (this.slug === "menu-backend-listar-rastreamento") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }

       if (this.slug === "menu-backend-listar-telemetria") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }

      if (this.slug === "menu-ativacao") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||               
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-backend-ativacao-ativacao") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||               
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-backend-ativacao-ativacao-bkp") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||               
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-backend-ativacao-ativacao-acessorio") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||               
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-backend-ativacao-desativacao") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||               
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-backend-ativacao-desativacao-backup") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||               
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-backend-ativacao-desativacao-acessorios") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||               
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-backend-ativacao-substituicao") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'CONFIGURADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }

      if (this.slug === "menu-backend-ativacao-substituicao-simcard") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'CONFIGURADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }

      if (this.slug === "menu-relatorios-relatorio-cliente") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'FINANCEIRO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')
               ) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-relatorios-relatorio-cliente-backup") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'FINANCEIRO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-relatorios-relatorio-veiculos-inativos") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'FINANCEIRO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }

      if (this.slug === "menu-relatorios-relatorio-senhas-bloqueio") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||               
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
        }
          return false;
      }

      if (this.slug === "menu-relatorios-relatorio-modulos-com-problema") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'CONFIGURADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'FINANCEIRO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-relatorios-relatorio-modulos") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'FINANCEIRO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-relatorios-relatorio-valor-mensalidade") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'FINANCEIRO') ||                
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-relatorios-relatorio-veiculos") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'FINANCEIRO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }

      if (this.slug === "menu-configuracao") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }

      if (this.slug === "menu-configuracao-envio-comando") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }
      if (this.slug === "menu-configuracao-status-rastreador") {
          if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_ATIVADOR') ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT')) {
                  return true;   
               }
          return false;
      }

      if (this.slug === "menu-backend-envio-comando-em-massa") {
           if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') || (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR')) {
                  return true;   
           }

           if ( (this.$store.state.AppActiveUser.perfil === 'EMPRESA') || (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPERVISOR')  ||
               (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO_CONCEPT') ) {
                  return true;   
           }

            
          return false;
      }

      if (this.slug === "menu-backend-envio-2a-via") {
            if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR') ||
                (this.$store.state.AppActiveUser.perfil === 'SUPERVISOR') ||
                (this.$store.state.AppActiveUser.perfil === 'ATIVADOR') ||
                (this.$store.state.AppActiveUser.perfil === 'CONFIGURADOR')) {
                    return true;   
            }
            return false;
      }
      if (this.slug === "menu-relatorios-relatorio-comissao") {
            if ( (this.$store.state.AppActiveUser.perfil === 'ADMINISTRADOR')) {
                    return true;   
            }
            return false;
      }

      return true; // Exibir se não tiver a regra
    },
    activeLink() {
      return ((this.to == this.$route.path) || (this.$route.meta.parent == this.slug) && this.to) ? true : false
    }
  }
}

</script>


